<template>
    <div class="page-test-fwa">
        page-test-fwa
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>